<template>
  <div>
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>角色管理</span>
      </div>
      <div>
        <el-button
            type="primary"
            @click="addRole"
            v-if="$store.state.routerRole.indexOf('11')!=-1 || $store.state.routerRole.indexOf('1')!=-1"
        >
          添加角色
        </el-button>
      </div>
    </div>

    <div class="tab pt-12">
      <el-tabs v-model="tabActiveName" @tab-click="tabsClick">
        <el-tab-pane name="0">
          <span slot="label">全部</span>
        </el-tab-pane>
        <el-tab-pane name="1">
          <span slot="label">已启动</span>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label">已关闭</span>
        </el-tab-pane>
      </el-tabs>
      <div class="tab-search">
        <el-input
            placeholder="请输入角色名"
            v-model="keyWord"
        >
          <i slot="suffix" class="el-icon-search" @click="searchClick"></i>
        </el-input>
      </div>
    </div>

    <el-table
        ref="table"
        :data="tableData"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
        v-loading="$store.state.loading"
        :header-cell-style="{
                      'color': '#18a78a'}"
    >
      <el-table-column
          label="编号"
          prop="number"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
          label="角色"
          prop="role"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
          label="修改日期"
          prop="modifiedDate"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
          label="状态"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.status | lockStatus }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          width="280px"
          align="center"
      >
        <template slot-scope="scope">
          <el-button type="primary" @click="editClick(scope.row)"
                     v-if="$store.state.routerRole.indexOf('13')!=-1 || $store.state.routerRole.indexOf('1')!=-1">编辑
          </el-button>
          <template v-if="scope.row.status!=='1'">
            <el-button
                type="warning"
                @click="statusRoleClick(scope.row,1)"
                v-if="$store.state.routerRole.indexOf('12')!=-1 || $store.state.routerRole.indexOf('1')!=-1"
            >开启
            </el-button>
          </template>
          <template v-if="scope.row.status!=='2'">
            <el-button
                type="info"
                @click="statusRoleClick(scope.row,2)"
                v-if="$store.state.routerRole.indexOf('14')!=-1 || $store.state.routerRole.indexOf('1')!=-1"
            >关闭
            </el-button>
          </template>

        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :append-to-body="true"
        ref="table"
        title="创建角色"
        top="20px"
        :visible.sync="roleDialog"
        width="650px"
        :before-close="roleDialogClose"
    >
      <div>
        <div class="content-main-title">
          <span>角色信息</span>
          <div class="hr"></div>
        </div>
        <div class="dialog-name">
          <span>角色名称</span>
          <el-input v-model="dialogName" placeholder="请输入内容"></el-input>
        </div>
        <div class="content-main-title">
          <span>权限设置</span>
          <div class="hr"></div>
        </div>
        <!--
         :default-expand-all="true"
        default-checked-keys 默认选择
        -->
        <el-tree
            :data="treeData"
            show-checkbox
            node-key="id"
            ref="tree"
            :default-checked-keys="defaultTree"
            :props="defaultProps">
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogClose">取 消</el-button>
        <el-button type="primary" @click="roleDialogSub">确 定</el-button>
      </div>
    </el-dialog>
    <div class="footerPage">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="30"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {role, menu, AddBasicRole, editRole, statusRole} from '@/api/module/basic'

export default {
  components: {},
  props: [],
  data() {
    return {
      //所有权限菜单
      treeData: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 46, 47, 48, 49, 51, 45, 52, 41, 42, 43, 44, 53, 2, 3, 4, 5, 6, 7, 8],
      defaultProps: {
        children: 'children',
        label: 'name'
      },

      tabActiveName: '0', // tab当前选择哪个
      roleDialog: false,
      page: 1, //当前第几页

      total: 0,//总数

      defaultTree: [],//树形 默认打开
      dialogId: '', //提交的id
      dialogName: '',// 角色名称
      keyWord: '',//搜索
      // 表格数据
      tableData: []
    };
  },
  mounted() {
    this.initLoad();
    this.menuAll();

  },
  //过滤器
  filters: {
    lockStatus(val) {
      switch (val) {
        case '1':
          return '启用'
        case '2':
          return '关闭'
      }
    }
  },
  methods: {
    //初始化数据
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.page,
        lock_status: this.tabActiveName === '0' ? '' : this.tabActiveName,
        role: this.keyWord
      }
      // let tabActiveName=this.tabActiveName;
      role(params).then((result) => {
        this.total = result.data.total;
        this.page = result.data.current_page;
        this.tableData = result.data.data.map(item => ({
          id: item.id,
          number: item.id,//编号
          role: item.role,//角色
          modifiedDate: this.Utils.timeDate(item.updated_at),//修改日期
          status: item.lock_status.toString(),//状态 1启动，2关闭
          menu_ids: item.menu_ids.split(','),//选择的权限
        }));
      })
    },
    //搜索
    searchClick() {
      this.page = 1;
      this.initLoad();
    },
    //拉取所有权限列表
    menuAll() {
      menu().then((result) => {
        this.treeData = result.data;
      })
    },
    // 点击编辑
    editClick(row) {
      this.defaultTree = row.menu_ids;//树形 默认打开)
      this.dialogId = row.id;
      this.dialogName = row.role// 角色名称
      this.$nextTick(function () {
        //Dom更新完毕
        this.$refs.tree.setCheckedKeys(this.defaultTree);
      });
      this.roleDialog = true;
    },
    //  tab 被点击
    tabsClick() {
      this.page = 1;
      this.initLoad();
    },
    //点击添加角色
    addRole() {
      this.dialogId = '';
      this.dialogName = '';
      this.defaultTree = []
      this.$nextTick(function () {
        //Dom更新完毕
        this.$refs.tree.setCheckedKeys([]);
      });
      this.roleDialog = true;
    },
    //dialog 点击确定
    roleDialogSub() {
      console.log(this.$refs.tree.getHalfCheckedKeys().toString())
      console.log(this.$refs.tree.getCheckedKeys().toString())
      this.page = 1;
      let data = {
        id: this.dialogId,
        role: this.dialogName,
        menu_ids: this.$refs.tree.getCheckedKeys().toString(), //获取树数据
        menu_id: this.$refs.tree.getHalfCheckedKeys().toString()//半选数据
      }
      console.log(data)
      if (this.dialogId === '') {
        AddBasicRole(data).then(() => {
          this.initLoad();
          this.roleDialogClose();
        })
      } else {
        editRole(data).then(() => {
          this.initLoad();
          this.roleDialogClose();
        })
      }
    },
    //切换状态按钮
    statusRoleClick(row, status) {
      let data = {
        id: row.id,
        lock_status: status
      }
      statusRole(data).then(() => {
        this.$message.success('修改状态成功');
        this.initLoad();
      })
    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.initLoad();

    },
    //dialog 关闭时候调用
    roleDialogClose() {
      this.roleDialog = false;
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.content-main-title {
  margin: 12px 0;
}

.dialog-name {
  @include flex();

  span {
    width: 70px;
    flex: 0 0 auto;
  }
}
</style>
