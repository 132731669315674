// 基础管理
import http from '../http';

//获取角色列表
export function role (params) {
    return http({
        url: "/api/user/role",
        method: "GET",
        params:params
    })
}

//所有权限菜单
export function menu () {
    return http({
        url: "/api/user/menu",
        method: "GET",
    })
}

//添加角色
export function AddBasicRole (data) {
    return http({
        url: "/api/user/role",
        method: "PUT",
        data:data
    })
}

//修改角色
export function editRole (data) {
    return http({
        url: "/api/user/role",
        method: "POST",
        data:data
    })
}

//删除角色
export function statusRole (data) {
    return http({
        url: "/api/user/roleLock",
        method: "POST",
        data:data
    })
}

//账号管理 列表
export function currentListData (params) {
    return http({
        url: "/api/user/user",
        method: "GET",
        params:params
    })
}
//账号管理 添加
export function currentListPut (data) {
    return http({
        url: "/api/user/register",
        method: "PUT",
        data:data
    })
}

//账号管理 编辑
export function currentListEdit (data) {
    return http({
        url: "/api/user/user",
        method: "POST",
        data:data
    })
}

//账号管理 状态更改
export function currentListStatus (data) {
    return http({
        url: "/api/user/userStatus",
        method: "POST",
        data:data
    })
}

//账号管理 删除
export function currentListDel (data) {
    return http({
        url: "/api/user/user",
        method: "DELETE",
        data:data
    })
}






